import React, { CSSProperties, useMemo } from 'react';

import FaIcon from './FaIcon';
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

import * as FSIcons from '@fortawesome/free-solid-svg-icons';
import * as FRIcons from '@fortawesome/free-regular-svg-icons';
import Validium from './icons/Validium';
import Engage from './icons/Engage';
import Wellbeing from './icons/Wellbeing';

const Icons = Object.freeze({
	edit: FSIcons.faPencil,
	delete: FSIcons.faTrash,
	create: FSIcons.faAdd,
	plus: FSIcons.faAdd,

	roles: FSIcons.faPeopleGroup,
	user: FSIcons.faPerson,

	tools: FSIcons.faScrewdriverWrench,
	time: FSIcons.faClock,
	warning: FSIcons.faTriangleExclamation,
	dashboard: FSIcons.faSquarePollVertical,

	calendar: FSIcons.faCalendarDays,
	list: FSIcons.faList,

	vanFront: FSIcons.faTruckFront,
	van: FSIcons.faTruck,
	car: FSIcons.faCar,

	gears: FSIcons.faGears,

	check: FSIcons.faCheck,
	close: FSIcons.faXmark,

	clipboard: FSIcons.faClipboard,
	eye: FSIcons.faEye,

	save: FRIcons.faSave,

	swap: FSIcons.faRetweet,

	creditCard: FRIcons.faCreditCard,

	chevronRight: FSIcons.faChevronRight,
	chevronLeft: FSIcons.faChevronLeft,
	chevronUp: FSIcons.faChevronUp,
	chevronDown: FSIcons.faChevronDown,

	search: FSIcons.faMagnifyingGlass,
	home: FSIcons.faHouse,

	infoCircle: FSIcons.faCircleInfo,
	info: FSIcons.faInfo,
	exclamation: FSIcons.faExclamation,
	exclamationTriangle: FSIcons.faTriangleExclamation,
	code: FSIcons.faCode,

	logout: FSIcons.faArrowRightFromBracket,
	login: FSIcons.faArrowRightToBracket,

	download: FSIcons.faDownload,
	history: FSIcons.faClockRotateLeft,

	excel: FSIcons.faFileExcel,

	qrcode: FSIcons.faQrcode,

	menu: FSIcons.faBars,
	carAccident: FSIcons.faCarBurst,

	phone: FSIcons.faPhone,

	shapes: FSIcons.faShapes,
	folder: FSIcons.faFolder,
	camera: FSIcons.faCamera,
	file: FSIcons.faFile,
	printer: FSIcons.faPrint,
	restore: FSIcons.faRotateLeft,

	calendarDay: FSIcons.faCalendarDay,

	hourglassEnd: FSIcons.faHourglassEnd,
	toolbox: FSIcons.faToolbox,

	boxArchive: FSIcons.faBoxArchive,

	suitcaseMedical: FSIcons.faSuitcaseMedical,

	lifeRing: FRIcons.faLifeRing,
	helmetSafety: FSIcons.faHelmetSafety,

	bell: FSIcons.faBellConcierge,

	handsHoldingChild: FSIcons.faHandsHoldingChild,
	snowPlow: FSIcons.faSnowplow,
	personFalling: FSIcons.faPersonFalling,
	film: FSIcons.faFilm,
	hotel: FSIcons.faHotel,
	houseCrack: FSIcons.faHouseCrack,
	roadBarrier: FSIcons.faRoadBarrier,
	peopleGroup: FSIcons.faPeopleGroup,
	coins: FSIcons.faCoins,
	comments: FRIcons.faComments,
	validium: Validium,
	book: FSIcons.faBook,
	engage: Engage,
	fileSignature: FSIcons.faFileSignature,
	tableColumns: FSIcons.faTableColumns,
	display: FSIcons.faDisplay,

	microphone: FSIcons.faMicrophone,
	heart: FSIcons.faHeart,

	checkList: FSIcons.faListCheck,

	link: FSIcons.faLink,

	clipboardQuestion: FSIcons.faClipboardQuestion,

	flag: FSIcons.faFlag,
	openfolder: FSIcons.faFolderOpen,

	wellbeing: Wellbeing,

	train: FSIcons.faTrain,
	envelope: FSIcons.faEnvelope,

	qa: FSIcons.faQuestionCircle,
});

console.log("ICON", FSIcons.faComments)

interface Props {
	icon: keyof typeof Icons,
	style?: CSSProperties
}

export default function CommonIcon(props: Props) {

	let icon = Icons[props.icon];
	return (
		<FaIcon style={props.style} as={(icon as IconDefinition)} />
	);
}

export type IconList = keyof typeof Icons;