import Styled from 'styled-components';
import Header from "./components/Header";
import { Outlet, useLocation, useNavigate } from "react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const AppContainer = Styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.videoBackdrop {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -10;
		object-fit: cover;
	}
	.backdrop {
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, .5);
		width: 100vw;
		height: 100vh;
	}
	.content {
		flex-grow: 1;
		z-index: 5;
	}
`;
import "./styles/transition.css";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import InstallPrompt from './components/InstallPrompt';
import Util from './Util';
import UAParser from 'ua-parser-js';
import ValidiumModal from './modals/ValidiumModal';
import OCMModal from './modals/OCMModal';
import VersionModal from './modals/VersionModal';

interface BeforeInstallPrompt extends Event {
	userChoice: Promise<{
		outcome: string,
		platform: string,
	}>,
	platforms: string[],
	prompt: () => void,
}

interface State {
	showInstallPrompt: boolean,
	currentModal?: string,
	isDesktop: boolean,
}

interface AppCTX extends State {
	showModal: (name: string) => void,
	getWorkerVersion: () => Promise<string>,
}

const AppContext = createContext<AppCTX>({
	showInstallPrompt: false,
	isDesktop: true,
	showModal: (name: string) => {},
	getWorkerVersion: async () => { return "Context Default"; },
});
const useApp = () => {
	return useContext(AppContext);
}

export { AppContext, useApp };

export default function App() {
	const location = useLocation();
	const navigate = useNavigate();
	const parser = new UAParser(navigator.userAgent);

	const [ state, setState ] = useState<State>({
		showInstallPrompt: false,
		isDesktop: false,
	});
	const installEvent = useRef<BeforeInstallPrompt>();

	const confirmInstall = () => {
		if (!installEvent.current) {
			// Likely shown on a device that doesn't support the install prompt.
			// Should show installation instructions.
			navigate("/install");
			return abortInstall();
		}
		installEvent.current.prompt();
		installEvent.current.userChoice
		.then(result => {
			abortInstall();
			// GC
			delete installEvent.current;
		})
	}
	const abortInstall = () => {
		setState(s => ({
			...s,
			showInstallPrompt: false,
		}))
	}

	const showModal = (name: string) => {
		console.log(`Opening Modal: ${name}`);
		setState(s => ({
			...s,
			currentModal: (name === "" ? undefined : name)
		}))
	}

	const getWorkerVersion = (): Promise<string> => {
		return new Promise((resolve, reject) => {
			if ('serviceWorker' in navigator) {
				navigator.serviceWorker.ready
				.then((registration) => {
					const serviceWorker = registration.active;
		
					if (serviceWorker) {
						navigator.serviceWorker.addEventListener('message', (event) => {
							if (event.data.type === 'version') {
								resolve(event.data.data);
							}
						});
				
						// Send the message to request the version
						serviceWorker.postMessage('getVersion');
					}
				});
			} else {
				resolve("Unknown")
			}
		});
	}

	useEffect(() => {
		const UAResult = parser.getResult();

		let isDesktop = false;
		if (UAResult.os.name === "Windows") {
			// Most likely desktop.
			isDesktop = true;
		}

		setState(s => ({
			...s,
			isDesktop,
		}))

		const query = new URLSearchParams(location.search);
		if (query.has("prompt")) {
			if (query.get("prompt") === "false") {
				// Don't prompt regardless
				return;
			} else if (query.get("prompt") === "true") {
				// Show regardless
				setState(s => ({
					...s,
					showInstallPrompt: true,
				}));
				return;
			}
		}

		const showInstallPrompt = (e: Event) => {
			const event = e as BeforeInstallPrompt;
			installEvent.current = event;
			
			e.preventDefault();
			setState(s => ({
				...s,
				showInstallPrompt: true,
			}));
		}
		if (Util.isApple() && Util.getDisplayMode() !== "standalone") {
			setState(s => ({
				...s,
				showInstallPrompt: true,
			}))
		}

		window.addEventListener('beforeinstallprompt', showInstallPrompt);

		return () => {
			window.removeEventListener('beforeinstallprompt', showInstallPrompt);
		}
	}, [])

	return (
		<AppContext.Provider value={{
			...state,
			showModal,
			getWorkerVersion,
		}}>
			<ValidiumModal />
			<OCMModal />
			<VersionModal />
			<AppContainer>
				<video style={{
					pointerEvents: "none",
					display: "none"
				}} className="videoBackdrop" autoPlay={true} loop={true} muted={true}>
					<source src="/assets/video/background.mp4" type="video/mp4"></source>
					<source src="/assets/video/background.webm" type="video/webm"></source>
				</video>
				<div className="backdrop"></div>

				<Header />
				<div className="content">
					<SwitchTransition>
						<CSSTransition
							key={location.hash}
							classNames={"fade"}
							timeout={250}
						>
							<Outlet />
						</CSSTransition>
					</SwitchTransition>
				</div>
			</AppContainer>
		</AppContext.Provider>
	);
}